import { createAction } from '@reduxjs/toolkit';

export const dashboardActions = {
	getDashboardDataAction: createAction('dashboard/getDashboardData'),
	getDashboardDataSuccessAction: createAction('dashboard/getDashboardDataSuccess'),
	getDashboardAnalyticAction: createAction('dashboard/getDashboardAnalytic'),
	getBrandsSuccessAction: createAction('dashboard/getBrandsSuccess'),
	getSourcesAction: createAction('dashboard/getSources'),
	getSourcesSuccessAction: createAction('dashboard/getSourcesSuccess'),
	getProductsSuccessAction: createAction('dashboard/getProductsSuccess'),
	getBrandAction: createAction('dashboard/getBrand'),
	getBalanceAnalyticSuccessAction: createAction('dashboard/getBalanceAnalyticSuccess'),
	// setTimeTopAction: createAction('dashboard/setTimeTop'),
	// setTimeBottomAction: createAction('dashboard/setTimeBottom'),
	// setProductTopAction: createAction('dashboard/setProductTop'),
	// setProductBottomAction: createAction('dashboard/setProductBottom'),
	setErrorAction: createAction('dashboard/setError'),
	clearDashboardDataAction: createAction('dashboard/clearDashboardData'),
};
