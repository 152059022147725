import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { uiActions } from '../actions/ui';
import { dashboardActions } from '../actions/dashboard';
import AffiliateService from '../../services/affiliates.service';
import { localesActions } from '../actions/locales';
import { stringifyText } from '../../utils/text';
import { logout } from './auth';

function* getDashboardData({ payload }) {
	const { brands, sources } = yield select(({ dashboard }) => dashboard);
	const { countries } = yield select(({ locales }) => locales);

	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getDashboardData,
		payload,
		dashboardActions.getDashboardDataSuccessAction
	);
	yield !brands?.length &&
		call(
			handleResponse,
			AffiliateService.getBrands,
			payload,
			dashboardActions.getBrandsSuccessAction
		);
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getCountrySuccessAction
		);
	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
	// yield call(
	// 	handleResponse,
	// 	AffiliateService.getBalanceAnalytic,
	// 	payload,
	// 	dashboardActions.getBalanceAnalyticSuccessAction
	// );
	yield put(uiActions.endLoaderAction());
}
function* getDashboardAnalytic({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getBalanceAnalytic,
		payload,
		dashboardActions.getBalanceAnalyticSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* getSources({ payload }) {
	const { sources } = yield select(({ dashboard }) => dashboard);

	yield put(uiActions.startLoaderAction());
	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* handleResponse(fetchFunc, payload, onSuccess) {
	try {
		const response = yield call(fetchFunc, payload);
		yield !!response?.data
			? onSuccess && put(onSuccess(response?.data))
			: call(handleError, response);
		return response;
	} catch (error) {
		yield handleError(error);
	}
}
function* handleError(e) {
	const error = e?.error || e;
	const errorText = stringifyText(error);

	yield put(dashboardActions.setErrorAction());
	yield put(
		uiActions.setAlertAction({
			show: true,
			severity: 'error',
			body: errorText,
		})
	);
	yield put(uiActions.endLoaderAction());
	if (errorText.includes('ERROR_NOT_AUTHORIZED')) {
		yield call(logout);
	}
}

function* watchDashboard() {
	yield takeLatest(
		dashboardActions.getDashboardDataAction.type,
		getDashboardData
	);
	yield takeLatest(
		dashboardActions.getDashboardAnalyticAction.type,
		getDashboardAnalytic
	);
	yield takeLatest(dashboardActions.getSourcesAction.type, getSources);
}
function* rootDashboard() {
	yield fork(watchDashboard);
}

export { rootDashboard };
